@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: local('PoppinsMedium'),
    url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

body {
  font-family: PoppinsRegular, sans-serif;
  background-color: #f5f6f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.2px !important;
}

p {
  margin: 0;
}

input:focus {
  border: 1px solid white;
  border-radius: 10px;
  outline: none;
}

button,pre {
  font-family: PoppinsRegular, sans-serif;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.ant-table-sticky-scroll-bar{
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: #eaecf1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 28px;
  background-color: #d5d9e2;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-selector:hover,
.ant-select-focused,
.ant-select-open,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00b27b !important;
}

.ant-picker-input input:focus{
  border: none;
}

.ant-picker-focused, -webkit-box-shadow{
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: #00b27b !important;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #00b27b;
  border-right-width: 1px !important
}

.ant-picker-range .ant-picker-clear {
  right: 9px;
}

.date-range-picker {
  padding: 9px;
  border-radius: 8px;
  width: 260px !important;
  height: 42px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #00b27b !important;
}
textarea {
  border-radius: 8px !important;
}
textarea:hover,
textarea:active,
textarea:focus {
  box-shadow: 0 0 0 2px #00b27b10 !important;
  border-color: #00b27b !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused,
.ant-select-open,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: 0 0 0 2px #00b27b10 !important;
  border-color: #00b27b !important;
}

.ant-select-selector,
.ant-select-selector:active,
.ant-select-selector:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.ant-select-selector .ant-input-affix-wrapper {
  border-radius: 8px !important;
  padding: 6px 12px;
}

.ant-select-selector {
  height: 42px !important;
  border-radius: 8px !important;
  padding: 1px 10px !important;
  overflow: auto !important;
}

.ant-selects-check {
  color: red !important;
}

.ant-select-item-option-selected {
  background-color: #53b35e25 !important;
}

.ant-select-selection-search-input {
  height: 100% !important;
}

.ant-spin.ant-spin-lg,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #00b27b !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}

.ant-btn:not([disabled]):hover,
.ant-btn:not([disabled]):focus {
  color: #10c78e;
  background: #fff;
  border-color: #10c78e;
}

.ant-btn-primary {
  background: #00b27b;
  color: #fff;
  border-color: #00b27b;
}

.ant-btn-primary:not([disabled]):hover,
.ant-btn-primary:not([disabled]):focus,
.ant-btn-primary:not([disabled]):active {
  background: #10c78e;
  color: #fff;
  border-color: #10c78e;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #00b27b;
  border-color: #00b27b;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00b27b;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6fff4cc;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcffe7;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  min-width: 60px;
}

.ant-spin-dot.ant-spin-dot-spin .ant-spin-dot-item {
  background: #00b27b;
}
.ant-modal {
  max-height: calc(100vh - 100px);
  top: 0;
}
.ant-modal-body {
  overflow: auto;
  max-height: 70vh;
}
.ant-modal-wrap {
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-table-container {
  overflow: hidden;
}

.create-genre-modal .ant-modal-body{ 
  padding: 8px;
  min-height: 80vh;
  height: 1px;
  overflow:  hidden;
}

.add_title_select_dropdown {
  display: none !important;
}

.dropdown-hamburger-menu {
  padding-inline: 6px;
  padding-block: 2px;
  border-radius: 3px;
}

.dropdown-hamburger-menu:hover {
  background-color: #f6f6f6;
}

.also-bought-modal .ant-modal-body{ 
  padding: 8px;
  min-height: 80vh;
  height: 1px;
  overflow:  scroll;
}

.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}

.ant-spin.ant-spin-lg.ant-spin-spinning {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-sticky-header {
  position: relative;
}

.category-rankings .ant-modal-body{ 
  padding: 10px;
  min-height: 80vh;
  overflow:  scroll;
}

.basic-btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: start;
}

li .basic-btn{
  padding: 0;
  margin: 0;
  width: 100%;
}