.save-search-input-container {
    position: relative;
}

.character-count {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #888;
}