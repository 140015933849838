.ant-tabs-tab {
  color: #aaadb5;
}

.ant-form-item-control-input .days-select {
  width: 300px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #00b27b !important;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 2px solid #bdbdbd60 !important;
}
.ant-tabs-ink-bar {
  background: #00b27b !important;
}
.ant-table-body::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #00b27b !important;
} 

.ant-steps-item-process .ant-steps-item-icon {
  border-color:  #00b27b !important;
}

.card-content-quantity {
  font-size: 36px !important;
  line-height: 1;
}

.filter-select .ant-select-selector {
  width: 260px !important;
}
.ant-select.ant-select-lg.ant-select-focused.filter-select.ant-select-single
  > span {
  display: none !important;
}

html {
  --antd-wave-shadow-color: none !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px !important;
}
.ant-col.ant-form-item-control {
  align-items: flex-start;
}

.td-actions {
  width: 80px !important;
}
.actions {
  width: 100%;
  min-width: 60px;
  max-width: 170px;
  display: flex;
  justify-content: space-around;
}

.actions .actions-icons {
  margin: 0px 2px;
  padding: 3px;
  font-size: 14px !important;
  border-radius: 3px;
}

.actions-icons.actions-icons-send {
  color: blue;
}
.actions-icons.actions-icons-flag {
  color: orange;
}
.actions-icons.actions-icons-eye {
  color: green;
}
.actions-icons.actions-icons-contact {
  color: maroon;
}

.actions-icons.actions-icons-pending-exclusion {
  color: rgb(153, 153, 42);
}

.actions-icons.actions-icons-trash {
  color: red;
}

.actions-icons.actions-icons-podium-title {
  color: #8108b3;
}
.actions-icons.actions-icons-return {
  color: #1a0523;
}
.actions-icons.actions-icons-chart {
  color: #b71360;
}
.actions-icons.actions-icons-chart:hover {
  background: #8b094615;
}
.actions-icons.actions-icons-return:hover {
  background: #1a052315;
}
.actions-icons.actions-icons-send:hover {
  background: rgba(0, 0, 235, 15%);
}
.actions-icons.actions-icons-flag:hover {
  background: rgba(255, 170, 12, 15%);
}
.actions-icons.actions-icons-eye:hover {
  background: rgba(1, 129, 1, 15%);
}

.actions-icons.actions-icons-contact:hover {
  background: rgb(255, 223, 223);
}

.actions-icons.actions-icons-pending-exclusion:hover {
  background: rgba(153, 153, 42, 15%);
}

.actions-icons.actions-icons-trash:hover {
  background: rgba(255, 6, 6, 15%);
}

.actions-icons.actions-icons-podium-title:hover {
  background: #8106b520;
}

.actions-icons.actions-icons-rankings {
  cursor: pointer;
  color: rgba(21, 78, 3, 0.714);
}

.actions-icons.actions-icons-rankings:hover {
  background: rgba(159, 216, 142, 0.714);
}

td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: inherit !important;
}

th.ant-table-cell.show {
  text-align: center;
}

.table-cell-asin,
.ant-btn.unhide-button:hover {
  color: #2ca880 !important;
  width: fit-content;
}

.table-cell-asin:hover {
  color: #25d29b;
}

.ant-table-row {
  background: white;
}

.td.ant-table-cell.show {
  padding: 2px !important;
}

.ant-btn.unhide-button {
  float: right;
  border: none;
  color: #066c4b;
}

.modal-menu {
  width: 100%;
  display: flex;
}

.ant-table {
  font-size: inherit !important;
}

.ant-table-cell.react-resizable {
  min-width: 130px;
  width: 130px;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}

.popover-content {
  padding: 5px;
  font-size: 12px;
}

.column-popover-info {
  display: flex;
  max-width: 150px;
  padding: 5px;
  font-size: 12px;
}

.sort {
  display: contents;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.sort svg {
  margin-left: 12px !important;
}

.settings {
  display: flex;
  justify-content: flex-end;
  padding: 7px;
  font-size: 24px;
  color: #9c9a9a;
}

.settings:hover {
  cursor: pointer;
  color: #00b27b;
}

.settings .anticon.anticon-setting {
  cursor: pointer;
}

.anticon.anticon-file-excel.file-excel {
  color: rgb(4, 104, 4);
}

.anticon.anticon-file-pdf.file-pdf {
  color: rgb(253, 16, 16);
}

.anticon.anticon-file-text.file-csv {
  color: #2c2c9b;
}

.disable-menu-item {
  color: rgb(174, 172, 172) !important;
  cursor: default !important;
}

.cell-add-tags {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(23, 23, 223);
  text-align: center;
  padding: 5px;
  font-weight: 500;
}

.cell-edit {
  display: flex;
  flex-direction: row;
}

.cell-delete {
  text-decoration: none;
  border: none !important;
  color: rgb(6, 106, 6) !important;
  margin-left: 5px !important;
  padding: 0px !important;
}

.cell-input {
  width: 87%;
}

.cell-input input {
  border: none;
  font-size: 12px;
  background-color: #00800012;
  color: rgb(6, 106, 6);
  padding: 2px 8px;
}

.list-primary-tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.primary-tags {
  background-color: rgb(238 198 198 / 19%);
  color: rgb(154, 8, 8);
  border-radius: 8px;
  padding: 6px;
  margin: 5px 0;
  width: 87%;
}

.ant-table-container {
  position: relative;
}

.ui-resizable-handle,
.ui-icon,
.ant-table {
  position: none;
}

.ui-resizable {
  position: unset !important;
}

.ant-row.ant-form-item.ant-form-item-has-success {
  max-width: 260px;
}

.ant-col.modal-select-col .ant-row.ant-form-item.ant-form-item-has-success {
  width: 100%;
  max-width: 100%;
}
.cell-fix {
  width: 10px !important;
  right: 0 !important;
}

.ant-select-selection-item {
  color: #848282;
}
.ant-select-multiple .ant-select-selection-item {
  background-color: white !important;
  border: 1px solid #cdcdcd !important;
  border-radius: 6px !important;
}

.ant-select-multiple .ant-select-selection-item .anticon.anticon-close:hover {
  color: #fb757b;
}

.wrapper-controller {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.ant-notification-notice.ant-notification-notice-success.ant-notification-notice-closable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ant-form,
.ant-form-item {
  font-size: unset !important;
}

.ant-input {
  font-size: inherit !important;
}

.sort-ascending path:first-child {
  fill: #00b27b;
}

.sort-descending path:last-child {
  fill: #00b27b;
}

.sorting-icon path {
  fill: #d9d9d9;
}

.table-cell-link {
  max-width: 25vw;
}

.user-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-stats-icon-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.user-stats-icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.user-piecharts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.users-column-graph-container {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px;
}

.chart-container {
  width: 100%;
}

.manage-users-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 25px;
}

@media (max-width: 768px) {
  .manage-users-btn-wrapper {
    flex-direction: column-reverse;
    align-items: end;
  }
}

.roles-table-container .ant-table-content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.roles-table-container .ant-table-content::-webkit-scrollbar {
  display: none;
}
