.ant-row.ant-form-item {
  margin: 0;
}

.filter {
  width: 100%;
}

.daily-rank-filter {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.filter-hot-titles {
  margin-right: 7px !important;
}

.filter-control-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 10px;
}

.filter-input {
  width: 360px !important;
  max-width: 260px;
  height: 42px;
  border-radius: 8px !important;
}

.filter-option {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-input-wrapper {
  display: flex;
  gap: 10px;
}

.filter-input-number {
  width: 360px !important;
  max-width: 125px;
  height: 42px;
  border-radius: 8px !important;
}

.filter-input-number .ant-input-clear-icon {
  font-size: 10px;
  margin: 0;
}

.deleted-item {
  color: red;
  font-size: 16px;
  margin-right: 10px;
}

.job-status-container {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #2ca880;
}

.data-refresh-time {
  color: #848282;
  padding: 6px;
  white-space: nowrap;
}

@media (max-width: 1300px) {
  .ant-table-body {
    width: 100% !important;
  }
}