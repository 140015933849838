.modal {
  width: 500px !important;
}

.modal-title {
  font-weight: 450;
  margin-bottom: 14px;
}

.modal-select-title {
  color: rgb(18, 86, 175);
}

.modal-select-col {
  padding-bottom: 20px;
}
