/* CustomLogin styles */

.form-container {
    display: flex;
    align-items: center;
    justify-content: justify;
    font-size: 14px;
    flex-wrap: wrap;
    gap: 14px;
    margin-bottom: 10px;
}

.form-item-label {
    font-weight: bold;
    margin-bottom: 8px;
}

.form-item-input {
    margin-bottom: 16px !important;
    width: 360px !important;
    height: 42px;
    border-radius: 8px !important;
}

.form-item-button button {
    margin-top: 16px;
    width: 100%;
    width: 360px !important;
    height: 42px;
    border-radius: 8px !important;
}

.form-item-button button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}