.overlay-card {
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    transition: box-shadow 0.3s ease-in-out;
}

.card-row {
    margin-top: 20px;
}

.overlay-card-img {
    height: 100px;
    width: 80px;
    object-fit: cover;
}

.overlay-zoomed-img {
    width: 100%;
    height: 550px;
}

.overlay-img {
    padding: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    max-width: 90px;
}

.overlay-img:hover {
    transform: scale(1.05);
}

.overlay-img:active {
    transform: scale(0.9);
}

.overlay-table {
    font-size: smaller;
    margin: 2px 0 10px 0;
    border-radius: 6px;
}

.overlay-even-row,
.overlay-odd-row {
    margin: 6px 0;
    padding: 3px 0;
    font-size: 13px;
}

.overlay-even-row {
    background-color: #ffffff;
}

.overlay-odd-row {
    background-color: #ece9e9;
}

.overlay-column {
    padding: 4px;
}

.col-tag {
    padding: 7px;
    flex-direction: row;
    flex-wrap: wrap;
}

.overlay-text-wrap {
    display: flex;
    flex-direction: column;
}

.overlay-author-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 4px;
    justify-content: space-between;
}

.overlay-author {
    display: flex;
    align-items: center;
}

.overlay-title-wrap {
    display: flex;
    font-size: 14px;
    white-space: pre-wrap;
}

@media only screen and (max-width: 1040px) {
    .overlay-title-wrap {
        flex-direction: column;
    }
}

.overlay-description-wrap {
    display: flex;
    white-space: pre-wrap;
}

.custom-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    width: 100%;
}

.header-notification {
    display: flex;
    justify-content: end;
    background: #ece9e9;
    padding: 8px;
    border-radius: 15px;
}

.copy-icon {
    font-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.copy-icon:hover {
    transform: scale(1.3);
}

.copy-icon:active {
    transform: scale(0.9);
}

.overlay-tags {
    display: flex;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    padding: 5px;
    margin: 2px 10px 2px 0px;
}

.predictions-graph {
    margin: 24px 0px 0px 0px;
}

.graph-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    color: #2ca880;
}

.overlay-action-tags {
    display: flex;
    padding: 10px;
}

.overlay-actions-row {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 10px
}