.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-scrollbar {
  right: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

thead
  .ant-table-cell.show.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  right: 8px !important;
}

tbody .ant-table-cell-fix-right-first {
  right: 0 !important;
}

.ui-resizable-e {
  width: 0;
  right: 0;
  top: 0;
  height: 0;
}

.ui-resizable-s {
  width: 0;
  right: 0;
  top: 0;
  height: 0;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -83px -226px;
}

.cursor-pointer {
  cursor: pointer;
}